/**
 * Маски для элемента формы input
 *
 * Маски будут срабатывать для тех элементов у которых есть следующие атрибуты
 * data-mask="text" - только текст
 * data-mask="inn-entity" - ИНН юридического лица
 * data-mask="date" - дата
 * data-mask="phone" - телефон
 * Для многострочного текста textarea пока используется класс
 * .input__input--textarea
 */

import 'inputmask/dist/inputmask.min.js'
import moment from "moment";
import 'moment/locale/ru';
moment.locale('ru');


export default class Mask {

  constructor(input) {
    this._element = input;
    this._input = input.querySelector(`.input__input`);
    this._addInputInnEntityMaskHandler = this._addInputInnEntityMaskHandler.bind(this);
    this._addInputPhoneMaskHandler = this._addInputPhoneMaskHandler.bind(this);
    this._addInputDateMaskHandler = this._addInputDateMaskHandler.bind(this);
    this._addInputTextMaskHandler = this._addInputTextMaskHandler.bind(this);
    this._addInputNotEmptyMaskHandler = this._addInputNotEmptyMaskHandler.bind(this);
    this._addInputTextNumberMaskHandler  = this._addInputTextNumberMaskHandler .bind(this);
    this._addInputTextareaMaskHandler = this._addInputTextareaMaskHandler.bind(this);
    this._addInputNameRuleHandler = this._addInputNameRuleHandler.bind(this);
    this._addInputFullNameRuleHandler = this._addInputFullNameRuleHandler.bind(this);
    this._addInputPassSizeHandler = this._addInputPassSizeHandler.bind(this);
    this._addInputMailHandler = this._addInputMailHandler.bind(this);
    this.init();
  }


  /**
   * Обработка события неполного заполнения поля с маской
   * @param value
   * @private
   */
  _onincompleteMask(value, text) {
    text = text == undefined ? `Введите корректное значение.` : text;
    if (value) {
      this._element.classList.add(`invalid`);
      this._element.setAttribute('data-invalid', text);
    }
  }

  /**
   * Обработка события успешного заполнения поля с маской
   * @param value
   * @private
   */
  _oncompleteMask() {
    this._element.classList.remove(`invalid`);
    this._element.setAttribute('data-invalid', '');
  }

  /**
   * Маска для поля ИНН юридического лица
   * @param input
   * @private
   */
  _addInputInnEntityMaskHandler(input) {
    let $this = this;
    new Inputmask({
      mask: "9999999999",
      placeholder: '__________',
      showMaskOnHover: false,
      autoUnmask: true,
      oncomplete: function() {
        $this._oncompleteMask();
      },
      onincomplete: function() {
        $this._onincompleteMask(this.value);
      }
    }).mask(input);
  }

  /**
   * Маска для поля с телефоном
   * @param input
   * @private
   */
  _addInputPhoneMaskHandler(input, isEngVersion) {
    let $this = this;
    let text = 'Это поле должно быть не меньше 11 символов.';
    if (isEngVersion) {
      text = 'This field must be at least 11 characters long.'
    }
    new Inputmask({
      mask: "+9(999)999-99-99",
      placeholder: '+_(___)___-__-__',
      showMaskOnHover: false,
      autoUnmask: true,
      oncomplete: function () {
        $this._oncompleteMask();
      },
      onincomplete: function () {
        if (this.value == '' && $this._element.getAttribute('required') == null) {
          $this._oncompleteMask();
        }
        else {
          if (input.dataset.showError != 'false') {
            $this._onincompleteMask(this.value, text)
          }
        }
      }
    }).mask(input);
  }

  /**
   * Маска для поля с именем
   * @param input
   * @private
   */
  _addInputNameRuleHandler(input, isEngVersion) {
    let $this = this;
    let text = 'Это поле должно быть не меньше 2 символов.';
    if (isEngVersion) {
      text = 'This field must be at least 2 characters long.'
    }
    new Inputmask({
      mask: "*{2,300}",
      definitions: {
        '*': {
          validator: "[А-Яа-яa-zA-Z ‘’\'\`\-]",
        }
      },
      placeholder: '',
      showMaskOnHover: false,
      autoUnmask: true,
      oncomplete: function () {
        if ($this._input.value[0] == ' ') {
          $this._input.value = $this._input.value.slice(1);
        }

        $this._oncompleteMask();
      },
      onincomplete: function () {
        $this._onincompleteMask(this.value, text);
      },
      oncleared: function () {
        if ($this._element.getAttribute('required') == null) {
          $this._oncompleteMask();
        }
      }
    }).mask(input);
  }

  /**
 * Маска для поля с ФИО
 * @param input
 * @private
 */
  _addInputFullNameRuleHandler(input) {
    let $this = this;
    new Inputmask({
      mask: ".{1}*{1,300}",
      definitions: {
        '*': {
          validator: "[А-Яа-яa-zA-Z \'\`\-]",
        },
        '.': {
          validator: "[А-Яа-яa-zA-Z\'\`\-]",
        }
      },
      placeholder: '',
      showMaskOnHover: false,
      autoUnmask: true,
      greedy: false,
      oncomplete: function () {

        let i_surname = this.value.split(' ')[0];
        let i_name = false;

        if (this.value.split(' ')[1] !== undefined) {
          i_name = this.value.split(' ')[1];
        }
        if (i_surname && i_name) {
          $this._oncompleteMask();
        } else {
          $this._onincompleteMask(this.value, 'Проверьте правильность введённых данных.');
        }
      },
      onincomplete: function () {
        $this._onincompleteMask(this.value, 'Это поле должно быть не меньше 2 символов');
      }
    }).mask(input);
  }

  /**
  * Маска для поля с паролем
  * @param input
  * @private
  */
  _addInputPassSizeHandler(input) {
    let $this = this;
    new Inputmask({
      mask: ".{6,40}",
      definitions: {
        '.': {
          validator: ".",
        }
      },
      placeholder: '',
      showMaskOnHover: false,
      autoUnmask: true,
      oncomplete: function() {
        $this._oncompleteMask();
      },
      onincomplete: function(){
        $this._onincompleteMask(this.value, 'Это поле должно быть не меньше 6 символов.');
      }
    }).mask(input);
  }

  /**
  * Маска для поля с email
  * @param input
  * @private
  */
  _addInputMailHandler(input, isEngVersion) {
    let $this = this;
    let text = 'Введите корректный email.';
    if (isEngVersion) {
      text = 'Incorrect email.';
    }

    new Inputmask({
      placeholder: '',
      mask: "*{2,50}",

      definitions: {
        '*': {
          validator: ".",
        }
      },
      showMaskOnHover: false,
      //autoUnmask: true,
      oncomplete: function () {
        let contact = this.value.split('@')[0];
        let mail_box = false;
        let mail_mask = false;

        if (this.value.split('@')[1] !== undefined) {
          let address = this.value.split('@')[1];
          mail_box = address.split('.')[0];
          mail_mask = address.split('.')[1];
        }
        //Должен быть и адрес контакта, и адрес почтовой службы, и адрес домена
        if (contact && mail_box && mail_mask) {
          $this._oncompleteMask();
        } else {
          this.parentElement.classList.add(`invalid`);
          this.parentElement.setAttribute('data-invalid', text);
        }
      },
      onincomplete: function () {
        $this._onincompleteMask(this.value, text);
      }
    }).mask(input);
  }

  /**
   * Маска для поля с датой
   * @param input
   * @private
   */
  _addInputDateMaskHandler(input) {
    let $this = this;

    const im = new Inputmask({
      alias: "datetime",
      inputFormat: "dd.mm.yyyy",
      placeholder: 'дд.мм.гггг',
      showMaskOnHover: false,
      mask: "99.99.9999",
      onincomplete: function(){
        $this._onincompleteMask(this.value, 'Это поле должно быть действительной датой.');
      },
      oncomplete: function() {
        let now = moment();
        let minDateForInput = moment().subtract(100, 'years');
        if (moment(this.value).isBefore(minDateForInput)) {
          this.value = minDateForInput.format('DD.MM.yyyy');
        } else if (moment(this.value).isAfter(now)) {
          this.parentElement.classList.add(`invalid`);
          let text = 'Это поле должно быть действительной датой.';
          this.parentElement.setAttribute('data-invalid', text);
        } else {
          this.parentElement.classList.remove(`invalid`);
          this.parentElement.setAttribute('data-invalid', '');
        }
      }
    });
    im.mask(input);
  }


  /**
 * Маска для поля с минимум двумя символами
 * @param input
 * @private
 */
  _addInputNotEmptyMaskHandler(input) {
    let $this = this;
    const im = new Inputmask({
      showMaskOnFocus: false,
      placeholder: "",
      oncomplete: function () {
        if (this.value.length < 2) {
          this.parentElement.classList.add(`invalid`);
          this.parentElement.setAttribute('data-invalid', 'Минимум 2 символа');
        }
        else {
          $this._oncompleteMask();
        }
      },
      onincomplete: function () {
        $this._onincompleteMask(this.value);
      },
    });
    im.mask(input);
  }
  /**
   * Маска для поля с текстом
   * @param input
   * @private
   */
  _addInputTextMaskHandler (input) {
    let $this = this;
    const im = new Inputmask({
      regex: "[А-Яа-яa-zA-Z - \'\`\-]*",
      showMaskOnFocus: false,
      placeholder: "",
      oncomplete: function() {
        $this._oncompleteMask();
      },
      onincomplete: function(){
        $this._onincompleteMask(this.value);
      },
    });
    im.mask(input);
  }

  /**
   * Маска для поля с текстом и цифрами
   * @param input
   * @private
   */
  _addInputTextNumberMaskHandler (input) {
    let $this = this;
    const im = new Inputmask({
      placeholder: '',
      mask: "*{1,300}",
      showMaskOnFocus: false,
      oncomplete: function() {
        $this._oncompleteMask();
      },
      onincomplete: function(){
        $this._onincompleteMask(this.value);
      },
    });
    im.mask(input);
  }

  /**
   * Маска для поля с многострочным текстом
   * @param input
   * @private
   */
  _addInputTextareaMaskHandler (input) {
    let $this = this;
    const im = new Inputmask({
      regex: "[А-Я,а-я,a-z,A-Z, ,0-9,\,,.,\',\",?,!,@,$,#,_,+,:,;,=,\\n,\\r,-]*",
      oncomplete: function() {
        $this._oncompleteMask();
      },
      onincomplete: function(){
        $this._onincompleteMask(this.value, "Это поле обязательно для заполнения.");
      },
    });
    im.mask(input);
  }


  init() {
    let isEngVersion = false;
    if (this._element.classList.contains('form-b--en')) {
      isEngVersion = true;
    }
    // маска для ИНН юридического лица
    const innEntityInput = this._element.querySelector(`.input__input[data-mask='inn-entity']`);
    if (innEntityInput) {
      this._addInputInnEntityMaskHandler(innEntityInput);
    }

    // маска для телефона
    const phoneInput = this._element.querySelector(`.input__input[data-mask='phone']`);
    if (phoneInput) {
      this._addInputPhoneMaskHandler(phoneInput, isEngVersion);
    }

    // маска для многострочного текста
    const textarea = this._element.querySelector(`.input__input--textarea`);
    if (textarea) {
      this._addInputTextareaMaskHandler (textarea);
    }

    const text_required = this._element.querySelector(`.input__input[data-mask='text-required']`);
    if (text_required) {
      this._addInputTextareaMaskHandler(text_required);
    }

    const inputname = this._element.querySelector(`.input__input[data-mask='name']`);
    if (inputname) {
      this._addInputNameRuleHandler(inputname, isEngVersion);
    }

    const inputfullname = this._element.querySelector(`.input__input[data-mask='fullname']`);
    if (inputfullname) {
      this._addInputFullNameRuleHandler(inputfullname);
    }

    const password = this._element.querySelector(`.input__input[data-mask='password']`);
    if (password) {
      this._addInputPassSizeHandler(password);
    }
    const inputmail = this._element.querySelector(`.input__input[data-mask='email']`);
    if (inputmail) {
      this._addInputMailHandler(inputmail, isEngVersion);
    }
    // маска для поля, в котором должно быть как минимум 2 символа
    const notEmptyInput = this._element.querySelector(`.js-input input[data-mask='notempty']`);
    if (notEmptyInput) {
      this._addInputNotEmptyMaskHandler(notEmptyInput);
    }
    const textInput = this._element.querySelector(`.input__input[data-mask='text']`);
    if(textInput) {
      this._addInputTextMaskHandler (textInput);
    }
    const textnumberInput = this._element.querySelector(`.input__input[data-mask='textnumber']`);
    if(textInput) {
      this._addInputTextNumberMaskHandler (textInput);
    }
    const dateInput = this._element.querySelector(`.input__input[data-mask='date']`);
    if (dateInput) {
      this._addInputDateMaskHandler(dateInput);
    }
  }
}
