import '~/assets/scss/modules.scss'

// JS
import '~/js/helpers/svg-sprite';
import '~/js/helpers/css-props';
import '~/js/modules/header';
import '~/js/modules/investments';
import '~/js/modules/cookiemsg';
import '~/js/modules/modals';
import '~/js/modules/side-window-feedback';

import '~/js/modules/modal';
import Tabs from '~/js/modules/tabs';
import '~/js/modules/lk-nav';
import Tooltip from '~/js/modules/tooltip';


window.addEventListener('DOMContentLoaded', () => {
    // вкладки
    const tabsControls = document.querySelectorAll('.js-tabs-controls');
    const tabsContainer = document.querySelectorAll('.js-tabs-container');
    if (tabsControls.length && tabsContainer.length) {
        tabsControls.forEach((control, i) => new Tabs(control, tabsContainer[i]));
    }

    //Инициализация tooltip
    const tooltips = document.querySelectorAll('.js-tooltip');
    if (tooltips.length) {
        tooltips.forEach((tooltip, i) => new Tooltip(tooltip));
    }

    /**
     * TODO удалить, но потом
     * @type {NodeListOf<Element>}
     */
    const mainTabsControls = document.querySelectorAll(".js-tabs-controls-main");
    const mainTabsContainer = document.querySelectorAll(".js-tabs-container-main");
    if (mainTabsControls.length && mainTabsContainer.length) {
        mainTabsControls.forEach((control, i) => new Tabs(control, mainTabsContainer[i], '-main'));
    }
});
