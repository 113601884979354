const lkNavOpen = document.querySelector(`.js-lk-nav-open`)
const lkNav = document.querySelector(`.lk-nav`)
const lkNavContent = document.querySelector(`.lk-nav__content`)
const lkNavCloses = document.querySelectorAll(`.js-lk-nav-close`)

const menuOpenClickHandler = () => {
  lkNav.classList.add('is-open');
  document.body.style.overflow = 'hidden';
}
const menuCloseClickHandler = () => {
  lkNav.classList.remove('is-open')
  document.body.style.overflow = 'scroll';
}

const menuContentClickHandler = (event) => {
  event.stopPropagation()
}

if(lkNavOpen) {
  lkNavOpen.addEventListener(`click`, menuOpenClickHandler)
}
if(lkNavCloses.length) {
  lkNavCloses.forEach((item) => item.addEventListener(`click`, menuCloseClickHandler))
}
if(lkNavContent) {
  lkNavContent.addEventListener(`click`, menuContentClickHandler)
}
