const modalOpenBtns = document.querySelectorAll("[data-modal-open]");
const modalCloseBtns = document.querySelectorAll("[data-modal-close]");

const modalOpenHandler = (name) => {
    document.querySelectorAll("[data-modal]").forEach((modal) => {
        if(modal.dataset.modal == name){
            modal.classList.add("is-active")
        }
    })
    document.querySelector("body").classList.add("no-scroll")
}

const modalCloseHandler = (name) => {
    document.querySelectorAll("[data-modal]").forEach((modal) => {
        if(modal.dataset.modal == name){
            modal.classList.remove("is-active")
        }
    })
    document.querySelector("body").classList.remove("no-scroll")
}

if (modalOpenBtns) {
    modalOpenBtns.forEach((el) => {
        el.addEventListener("click", (e) => {
            e.preventDefault();
            modalOpenHandler(el.dataset.modalOpen)
        })
    })
}

if(modalCloseBtns){
    modalCloseBtns.forEach((el) => {
        el.addEventListener("click", () => {
           modalCloseHandler(el.dataset.modalClose)
        })
    })
}
