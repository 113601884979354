import smoothScroll from '~/js/helpers/smooth-scroll';

const upward = document.querySelector(`.upward`);
if (upward) {
  const toggleUpwardClass = () => {
    if (window.pageYOffset > 0) {
      upward.classList.add(`active`);
    } else {
      upward.classList.remove(`active`);
      upward.removeAttribute(`disabled`);
    }
  };

  window.addEventListener(`DOMContentLoaded`, () => {
    toggleUpwardClass();

    upward.addEventListener(`click`, () => {
      upward.setAttribute(`disabled`, true);
      smoothScroll(0, 500, false);
    });

    window.addEventListener(`scroll`, () => {
      toggleUpwardClass();
    });
  });
}