import getScrollbarWidth from './get-scroll-width';

const setCssProperties = () => {
  document.body.classList.add(`resized`);

  // Scrollbar width
  const scrollbarWidth = getScrollbarWidth();
  document.documentElement.style.setProperty(
      `--scrollbar-width`,
      `${scrollbarWidth}px`
  );

  // Viewport height
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty(
      `--vh`,
      `${vh}px`
  );

  // Filters list height
  const filtersList = document.querySelector(`.filters__list`);
  if (filtersList) {
    document.documentElement.style.setProperty(
        `--filters-list-height`,
        `${filtersList.scrollHeight}px`
    );
  }

  // Filters active height
  const filtersActive = document.querySelector(`.filters__active`);
  if (filtersActive) {
    document.documentElement.style.setProperty(
        `--filters-active-height`,
        `${filtersActive.scrollHeight - 2}px`
    );
  }

  // Form image height
  const form = document.querySelector(`.form__form`);
  if (form) {
    document.documentElement.style.setProperty(
        `--form-image-height`,
        `${form.scrollHeight}px`
    );
  }

  document.body.classList.remove(`resized`);
};

window.addEventListener(`DOMContentLoaded`, setCssProperties);
window.addEventListener(`resize`, setCssProperties);
