import {createPopper} from '@popperjs/core';

export default class Tooltip {
  constructor(button, options) {
    this._button = button;
    this._options = options || {
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 8],
          }
        }
      ]
    };
    this._dataTooltip = button.getAttribute('data-tooltip');
    this._dataTooltipPlacement = button.getAttribute('data-tooltip-placement');
    this._dataTooltipOffset = button.getAttribute('data-tooltip-offset');
    this._dataTooltipType = button.getAttribute('data-tooltip-type');
    this._tooltip = document.querySelector(`.tooltip[data-tooltip-buttons=${this._dataTooltip}]`);
    this._popperInstance = null;

    this._show = this._show.bind(this);
    this._destroy = this._destroy.bind(this);
    this._hide = this._hide.bind(this);
    this._hidePopup = this._hidePopup.bind(this);

    this.init();
  }

  _show() {
    this._tooltip.setAttribute('data-show', '');
    this.init();
  }

  _destroy() {
    if (this._popperInstance) {
      this._popperInstance.destroy();
      this._popperInstance = null;
    }
  }

  _hide() {
      this._tooltip.removeAttribute('data-show');
      this._destroy();
    }

  _hidePopup() {
    this._tooltip.removeAttribute('data-show');
    this._destroy();
  }

  init() {


    if(this._dataTooltipPlacement) {
      this._options.placement = this._dataTooltipPlacement ? this._dataTooltipPlacement : 'bottom';
    }

    if(this._dataTooltipOffset) {
      this._options.modifiers = [{name: 'offset', options: {offset: [0, this._dataTooltipOffset]}}];
    }

    this._popperInstance = createPopper(this._button, this._tooltip, this._options);

    if(this._dataTooltipType == 'popup') {
      window.addEventListener('scroll',  this._hidePopup);
      document.addEventListener('click', this._hidePopup);
    }

    const showEvents = ['mouseenter', 'focus'];
    const hideEvents = ['mouseleave', 'blur'];

    showEvents.forEach(event => {
      this._button.addEventListener(event, this._show);
    });

    hideEvents.forEach(event => {
      this._button.addEventListener(event, this._hide);
    });



  }
}
