import Input from '~/js/modules/input';

export default class Form {
  constructor(form) {
    this._element = form;
    this._inputs = this._element.querySelectorAll('.input');
    this._inputComponents = [];

    if(!this._element.classList.contains('form__profile')) {
      this._formResetHandler = this._formResetHandler.bind(this);
    }

    this.init();
  }

  _addLinkTabHandler(link) {
    link.addEventListener("click", (evt) => {
      const tab = link.getAttribute("data-tab");
      const wrap = link.closest('.js-tabs-container');
      const targetBlock = wrap.querySelector(`.js-tabs-block[data-tab="${tab}"]`);
      const targetBlocks = wrap.querySelectorAll(".js-tabs-block");
      if (targetBlocks.length) {
        for (const block of targetBlocks) {
          block.classList.remove("active");
        }
      }
      const targetItems = wrap.querySelectorAll(".js-tabs-item");
      if (targetItems.length) {
        for (const item of targetItems) {
          item.classList.remove("active");
        }
      }
      targetBlock.classList.add("active");
    });
  }

  _formResetHandler(event) {
    // if (this._inputComponents.length) {
    //   this._inputComponents.forEach((input) => {
    //     console.log(input.querySelector('input'));
    //     input.querySelector('input').reset();
    //   });
    // }
  }

  init() {
    if (this._inputs.length) {
      for (const input of this._inputs) {
        this._inputComponents.push(new Input(input));
      }
    }

    if (document.querySelector('form[data-form-name="fullname_form"]')) {
      //Запись в скрытые поля формы для регистрации
      let form = document.querySelectorAll('form[data-form-name="fullname_form"]');
      if (form.length) {
        for (const item of form) {

          let nameSurnamePatronymic = item.querySelector('[name=fname]');
          if (nameSurnamePatronymic) {
            nameSurnamePatronymic.onchange = function () {
              let i_surname = this.value.split(' ')[0];
              let i_name = false;
              let i_patro = false;

              if (this.value.split(' ')[1] !== undefined) {
                i_name = this.value.split(' ')[1];
              }
              if (this.value.split(' ')[2] !== undefined) {
                i_patro = this.value.split(' ')[2];
              }

              item.querySelector('[name=name]').value = i_name ? i_name : '';
              item.querySelector('[name=surname]').value = i_surname ? i_surname : '';
              item.querySelector('[name=patronymic]').value = i_patro ? i_patro : '';

              // запись значений в скрытыe поля для отправки в CRM
              if (item.getAttribute("data-send-lead")) {
                if (item.querySelector('[name=page_url]')) {
                  item.querySelector('[name=page_url]').value = window.location;
                }
                if (item.querySelector('[name=page_path_url]')) {
                  let alias = window.location.pathname.split('/');
                  item.querySelector('[name=page_path_url]').value = alias ? alias[alias.length - 1] : '';
                }
                if (item.querySelector('[name=page_title]')) {
                  item.querySelector('[name=page_title]').value = document.querySelector('h1').textContent;
                }
                if (item.querySelector('[name=event_id]')) {
                  item.querySelector('[name=event_id]').value = item.querySelector('[name=event_id]').getAttribute('data-value');
                }
                if (item.querySelector('[name=lead_type_id]')) {
                  item.querySelector('[name=lead_type_id]').value = (item.querySelector('[name=landing_id]') && item.querySelector('[name=landing_id]').getAttribute('data-value')) ? item.querySelector('[name=landing_id]').getAttribute('data-value') : item.getAttribute('data-lead-type');
                }
                let nameSurname = item.querySelector('[name=name_surname]');
                if (nameSurname) {
                  let name = item.querySelector('[name=name]') ? item.querySelector('[name=name]').value : '';
                  let surname = item.querySelector('[name=surname]') ? item.querySelector('[name=surname]').value : '';
                  nameSurname.value = `${surname} ${name}`;
                }
              }
            }
          }
        }
      }
    }

    this._element.addEventListener('reset', this._formResetHandler);

    const comment = document.getElementsByName('comment');
    if (comment) {
      comment.forEach((comm) => {
        let commentCounter = comm.parentElement.querySelector('.counter-comment__current')
        comm.oninput = () => {
          if(commentCounter) {
            const length = comm.value.length
            commentCounter.textContent = length
            comm.style.height = 'auto';
            comm.style.height = (comm.scrollHeight) + 'px';
          }
        };
      });
    }
    // поведение дополнительной ссылки открытия таба
    this._jsLinkTabs = this._element.querySelectorAll(".js-link-tab");
    if(this._jsLinkTabs.length) {
      this._jsLinkTabs.forEach((link) => this._addLinkTabHandler(link));
    }

    // запись значений в скрытыe поля для отправки в CRM
    if(this._element.getAttribute("data-send-lead")) {
      let form = this._element;
      let nameSurnameInpits = form.querySelectorAll('[name=surname], [name=lastname], [name=name]');
      if (nameSurnameInpits.length) {
        nameSurnameInpits.forEach(function (item, i, arr) {
          item.onchange = function () {
            if (form.querySelector('[name=page_url]')) {
              form.querySelector('[name=page_url]').value = window.location;
            }
            if (form.querySelector('[name=page_path_url]')) {
              let alias = window.location.pathname.split('/');
              form.querySelector('[name=page_path_url]').value = alias ? alias[alias.length - 1] : '';
            }
            if (form.querySelector('[name=page_title]')) {
              form.querySelector('[name=page_title]').value = document.querySelector('h1').textContent;
            }
            if (form.querySelector('[name=event_id]')) {
              form.querySelector('[name=event_id]').value = form.querySelector('[name=event_id]').getAttribute('data-value');
            }
            if (form.querySelector('[name=lead_type_id]')) {
              form.querySelector('[name=lead_type_id]').value = (form.querySelector('[name=landing_id]') && form.querySelector('[name=landing_id]').getAttribute('data-value')) ? form.querySelector('[name=landing_id]').getAttribute('data-value') : form.getAttribute('data-lead-type');
            }
            let nameSurname = form.querySelector('[name=name_surname]');
            if (nameSurname) {
              let name = form.querySelector('[name=name]') ? form.querySelector('[name=name]').value : '';
              let surname = '';
              if (form.querySelector('[name=surname]')) {
                surname = form.querySelector('[name=surname]').value;
              }
              if (form.querySelector('[name=lastname]')) {
                surname = form.querySelector('[name=lastname]').value;
              }
              nameSurname.value = `${surname} ${name}`;
            }

            if (form.querySelector('[name=job]')) {
              form.querySelector('[name=job]').value = form.querySelector('[name=job]').getAttribute('data-value');
            }
            if (form.querySelector('[name=account]')) {
              form.querySelector('[name=account]').value = form.querySelector('[name=account]').getAttribute('data-value');
            }
          };
        });
      }
      //после попытки отправки формы скроллим на нужный блок, если он есть
      const scrollToBlock = document.querySelector('.js-form-success');
      if (scrollToBlock) {
        form.addEventListener("submit", function () {
          this.scrollIntoView({
            block: "start",
            behavior: "smooth"
          });
        });
      } else {
        //после попытки отправки формы скроллим на нужный блок внутри чанка с формой, если он есть
        const scrollToBlockInsideForm = form.querySelector('.js-form-success-inside');
        if (scrollToBlockInsideForm) {
          form.addEventListener("submit", function () {
            this.scrollIntoView({
              block: "start",
              behavior: "smooth"
            });
          });
        }
      }
    }
  }
}
