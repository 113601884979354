export default class Tabs {
  constructor(controls, container, postfix = '') {
    this._postfix = postfix;
    this._controls = controls;
    this._list = controls.querySelector(`.js-tabs-list${this._postfix}`);
    this._items = Array.from(controls.querySelectorAll(`.js-tabs-item${this._postfix}`));
    this._links = Array.from(controls.querySelectorAll(`.js-tabs-link${this._postfix}`));
    this._container = container;
    this._blocks = Array.from(container.querySelectorAll(`.js-tabs-block${this._postfix}`));

    if (this._items.length) {
      this._items.forEach((item, index) => {
        if(index) item.classList.remove('active');
        else item.classList.add('active');
      });
    }
    if (this._blocks.length) {
      this._blocks.forEach((block, index) => {
        if(index) block.classList.remove('active');
        else block.classList.add('active');
      });
    }
    this._activeItem = this._items.reduce((acc, cur) => cur.classList.contains(`active`) ? cur : acc);
    this._activeBlock = this._blocks.reduce((acc, cur) => cur.classList.contains(`active`) ? cur : acc);

    this._linkClickHandler = this._linkClickHandler.bind(this);

    this.init();
  }

  _linkClickHandler(evt) {
    const target = evt.currentTarget;
    const targetItem = target.closest(`.js-tabs-item${this._postfix}`);
    const targetBlockId = target.dataset.tab;
    const targetBlock = this._container.querySelector(`.js-tabs-block${this._postfix}[data-tab="${targetBlockId}"]`);
    const targetBlockIndex = this._blocks.indexOf(targetBlock);

    this._activeItem.classList.remove(`active`);

    if (this._blocks.length) {
      this._blocks.forEach((block, index) => {
        block.classList.remove('active');
      });
    }

    // при наличии вкдалок внутри других вкладок в Chrome начинается эффект "мигания" внутренних вкладок
    // для решения этой проблемы добавлен класс not-animations
    // если у tabs__block есть класс not-animations, то анимация не работает
    // добавляем класс not-animations ко всем елементам с классом tabs__block
    let blocks = document.querySelectorAll('.tabs__block')
    if(blocks.length) {
      blocks.forEach((block) => {
        block.classList.add('not-animations');
      })
    }

    targetItem.classList.add(`active`);
    targetBlock.classList.remove(`not-animations`);
    setTimeout(function(){
      targetBlock.classList.add(`active`);
    });

    // this._container.style.height = `${targetBlock.scrollHeight}px`;

    this._activeItem = targetItem;
    this._activeBlock = targetBlock;

    this._setCoords();
  }

  _setCoords() {
    if (window.innerWidth < 1024) {
      const listWidth = this._list.offsetWidth;
      const activeItemWidth = this._activeItem.offsetWidth;
      const activeItemOffsetLeft = this._activeItem.getBoundingClientRect().left;

      this._list.scrollLeft += activeItemOffsetLeft - Math.abs(listWidth - activeItemWidth) / 2;
    }
  }

  _get(key) {
    var s = window.location.search;
    s = s.match(new RegExp(key + '=([^&=]+)'));
    return s ? s[1] : false;
  }

  init() {
    this._setCoords();

    if (this._activeBlock) {
      // this._container.style.height = `${this._activeBlock.scrollHeight}px`;
    }


    if (this._links.length) {
      this._links.forEach((link) => {
        link.addEventListener(`click`, this._linkClickHandler);
      });
    }

    // на странице фондов, при прорисовке графиков в матричном отображении фондов,
    // если они помещены в неактивную вкладку графики прорисовываются некорректно - слишком большие
    // для таких ситуаций добавлен класс onload, который удаляется после прорисовки контента нужной вкладки
    if (this._blocks.length) {
      this._blocks.forEach((block, i) => {
        setTimeout(function(){
          block.classList.remove(`onload`)
        });
      });
    }

    let rid = this._get('rid');

    //checking for get parameter and activating the desired filter
    if(rid) {

      let el = this._controls.querySelector(`.js-tabs-link${this._postfix}[data-tab="tab${rid}"]`);
      if(el) {
        el.click();
      }

      // if(rid) {
      //   window.history.replaceState(null, null, window.location.pathname);
      // }

    }

  }
}
