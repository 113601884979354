import {clearAllBodyScrollLocks} from 'body-scroll-lock';
import '~/js/modules/main-nav';
import '~/js/modules/upward';
import Keycloak from 'keycloak-js'

window.addEventListener(`DOMContentLoaded`, () => {
  const header = document.querySelector(`.header`);
  if (header) {
    const links = document.querySelectorAll(`a[href='#']`);
    links.forEach((link) => link.addEventListener(`click`, (evt) => evt.preventDefault()));
    const mainNavItems = header.querySelectorAll(`.main-nav__item`);

    window.addEventListener(`resize`, () => {
      if (window.innerWidth >= 1280) {
        clearAllBodyScrollLocks();
        header.classList.remove(`opened`);
        mainNavItems.forEach((item) => {
          item.classList.remove(`opened`);
        });
      }
    });
    window.addEventListener(`scroll`, () => {
      if (header.classList.contains(`opened`)) {
        return;
      }
    });

    const button = document.getElementById('login__menu-button');
    const dropdown = document.getElementById('login-menu');

    if(button) {
      button.onclick = function (event) {
        event.preventDefault();
        dropdown.classList.toggle('open');
      };
    }

    if(dropdown) {
      window.onclick = function (event) {
        if(!button.contains(event.target)) {
          if (dropdown.classList.contains('open')) {
            dropdown.classList.remove('open');
          }
        }
      };
    }
  }

  // Антиспам
  const forms = document.querySelectorAll('form')
  if (forms) {
    forms.forEach((f) => {
      if (f.getAttribute('method') != 'get') {
        f.insertAdjacentHTML('beforeend','<input type="text" name="org" value="" class="_org" style="visibility:hidden; height: 0; width: 0; padding: 0; border:none; position: absolute"/>')
      }
    })
  }
// Антиспам х
});


window.addEventListener(`DOMContentLoaded`, async () => {

  const authForm = document.querySelector('.js-auth-form')
  const unisenderStocks = document.getElementById('unisenderStocks')
  const unisenderBonds = document.getElementById('unisenderBonds')
  const unisenderReviews = document.getElementById('unisenderReviews')

  const keycloak = new Keycloak({
    url: 'https://login-am.dohod.ru',
    realm: 'analytics',
    clientId: 'web-client'
  });

  try {
    const authenticated = await keycloak.init({
      checkLoginIframe: false,
      onLoad: 'check-sso',
      silentCheckSsoFallback: false,
      enableLogging: true,
      silentCheckSsoRedirectUri: window.location.origin + "/silent-check-sso.html"
    })

    const openContent = document.querySelectorAll('.js-kc-auth-open-content')
    const closeContent = document.querySelectorAll('.js-kc-auth-close-content')

    if (authenticated) {
      console.log('User is authenticated')
      if (openContent) {
        openContent.forEach((c) => {
          if(c.classList.contains('menu-btn-enter')) {
            c.style.display = 'flex'
          }
          else {
            c.style.display = 'block'
          }
        })
      }
      if (closeContent) {
        closeContent.forEach((c) => {
          c.style.display = 'none'
        })
      }

      try {
        const profile = await keycloak.loadUserProfile()
        const userDataEmail = document.querySelector('.js-user-email')
        const userDataLogin = document.querySelector('.js-user-login')
        const userDataFirstname = document.querySelector('.js-user-firstname')
        const userDataLastname = document.querySelector('.js-user-lastname')

        fetch('/assets/components/dohodusers/connectorweb.php?action=info', {
          method: "POST",
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({id: profile.id})
        }).then((response) => response.json())
          .then((json) => {
            if (unisenderStocks) {
              if (json.stocks) {
                unisenderStocks.setAttribute('checked', true)
                unisenderStocks.dataset.checked = true
              } else {
                unisenderStocks.removeAttribute('checked')
                unisenderStocks.dataset.checked = false
              }
            }
            if (unisenderBonds) {
              if (json.bonds) {
                unisenderBonds.setAttribute('checked', true)
                unisenderBonds.dataset.checked = true
              } else {
                unisenderBonds.removeAttribute('checked')
                unisenderBonds.dataset.checked = false
              }
            }
            if (unisenderReviews) {
              if (json.reviews) {
                unisenderReviews.setAttribute('checked', true)
                unisenderReviews.dataset.checked = true
              } else {
                unisenderReviews.removeAttribute('checked')
                unisenderReviews.dataset.checked = false
              }
            }
          })

        unisenderStocks.addEventListener('click', (e) => {
          if (unisenderStocks.dataset.checked == 'true') {
            unisenderStocks.dataset.checked = false
          } else {
            unisenderStocks.dataset.checked = true
          }
        })
        unisenderBonds.addEventListener('click', (e) => {
          if (unisenderBonds.dataset.checked == 'true') {
            unisenderBonds.dataset.checked = false
          } else {
            unisenderBonds.dataset.checked = true
          }
        })
        unisenderReviews.addEventListener('click', (e) => {
          if (unisenderReviews.dataset.checked == 'true') {
            unisenderReviews.dataset.checked = false
          } else {
            unisenderReviews.dataset.checked = true
          }
        })

        if (authForm) {
          authForm.addEventListener('submit', (e) => {
            e.preventDefault()
            let unisenderStocks = authForm.querySelector('#unisenderStocks')
            let unisenderBonds = authForm.querySelector('#unisenderBonds')
            let unisenderReviews = authForm.querySelector('#unisenderReviews')
            let data = {
              'unisender' : {
                'stocks':(/true/).test(unisenderStocks.dataset.checked),
                'bonds':(/true/).test(unisenderBonds.dataset.checked),
                'reviews':(/true/).test(unisenderReviews.dataset.checked)
              },
              'id': profile.id,
              'email': profile.email
            }
            // console.log(data);
            fetch('/assets/components/dohodusers/connectorweb.php?action=info', {
              method: "POST",
              headers: {'Content-Type': 'application/json'},
              body: JSON.stringify(data)
            }).then((response) => response.json())
              .then((json) => {
                // console.log(json)
              });

            return false

          })
        }

        if (userDataEmail) {
          userDataEmail.value = profile.email
        }
        if (userDataLogin) {
          userDataLogin.innerHTML = profile.email
        }
        if (userDataFirstname) {
          userDataFirstname.value = profile.firstName
        }
        if (userDataLastname) {
          userDataLastname.value = profile.lastName
        }
        console.log('Retrieved user profile:', profile);
      } catch (error) {
        console.error('Failed to load user profile:', error);
      }

    } else {
      console.log('User is not authenticated')
      if (openContent) {
        openContent.forEach((c) => {
          c.style.display = 'none'
        })
      }
      if (closeContent) {
        closeContent.forEach((c) => {
          if(c.classList.contains('menu-btn-enter')) {
            c.style.display = 'flex'
          }
          else {
            c.style.display = 'block'
          }
          c.querySelectorAll('a').forEach((a) => {
            a.setAttribute('href', '#')
            a.addEventListener(`click`, async () => {
              await keycloak.login();
            })
          })
        })
      }

    }
  } catch (error) {
    console.error('Failed to initialize adapter:', error);
  }


  const loginBtns = document.querySelectorAll('.js-kc-auth-login')
  if (loginBtns) {
    loginBtns.forEach((lib) => {
      lib.addEventListener(`click`, async () => {
        const login = await keycloak.login({
        });
      })
    })
  }

  const logoutBtns = document.querySelectorAll('.js-kc-auth-logout')
  if (logoutBtns) {
    logoutBtns.forEach((lob) => {
      lob.addEventListener(`click`, async () => {
        const logout = await keycloak.logout({
        });
      })
    })
  }

  const changePasswordBtn = document.querySelector('.js-change-password-button')
  if (changePasswordBtn) {
    changePasswordBtn.addEventListener(`click`, async () => {
        const changePasswor = await keycloak.login({
          action: 'UPDATE_PASSWORD'
        });
      })
  }

  const authFormBtn = document.querySelector('.js-auth-form-button')
  if (authFormBtn) {
    authFormBtn.addEventListener(`click`, async () => {
      MicroModal.show('modal-save-profile')
      })
  }

});
