import {clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll} from 'body-scroll-lock';
import hoverintent from 'hoverintent';

const header = document.querySelector(`.header`);
if (header) {
  const menuToggleBtn = header.querySelector(`.js-logo-menu-toggle-btn`);
  const mainNavList = header.querySelector(`.main-nav__list`); 
  const accordions = header.querySelectorAll(`.accordion__item`);
  const mobBanners = header.querySelectorAll(`.mobile-nav-banner-container`);

  if (mainNavList) {
    const mainNavItems = mainNavList.querySelectorAll(`.main-nav__item`);
    const mainNavLinks = mainNavList.querySelectorAll(`.main-nav__link--triangle`);
    let pageOffsetTop = 0;

    const menuToggleBtnClickHandler = () => {
      header.scroll(0, 0);
      window.scroll(0, pageOffsetTop);
      header.classList.toggle(`opened`);
      header.classList.toggle(`active`);
      document.querySelector(`body`).classList.toggle('no-scroll')
      clearAllBodyScrollLocks();

      if (accordions) {
        accordions.forEach((item) => {
          item.classList.remove(`opened`);
          item.classList.remove(`active`);
          if(window.innerWidth >= 768 && window.innerWidth < 1280 && item.dataset.type == 'analytic-menu') {
            item.classList.add(`opened`);
            item.classList.add(`active`);

            mobBanners.forEach((mb) => {
              if(mb.classList.contains(item.dataset.type)) {
                mb.style.display = 'flex';
              }
            });
          }
        });
      }
    };

    const mainNavLinkClickHandler = (evt) => {
      if (window.innerWidth < 1280) {
        const currentItem = evt.currentTarget.closest(`.main-nav__item`);
        if(currentItem.classList.contains(`accordion__item`)) {
          evt.preventDefault();
          const currentItemContent = currentItem.querySelector(`.main-nav__content`);

          header.scroll(0, 0);
          currentItemContent.scroll(0, 0);
          currentItem.classList.toggle(`opened`);
          currentItem.classList.toggle(`active`);

          currentItem.parentNode.querySelectorAll('.accordion__item').forEach((item) => {
            if(item != currentItem) {
              item.classList.remove(`opened`);
              item.classList.remove(`active`);
            }

            mobBanners.forEach((mb) => {
              if(mb.classList.contains(item.dataset.type)) {
                if(item != currentItem) {
                  mb.style.display = 'none';
                }
                else {
                  if(currentItem.classList.contains(`active`)) {
                    mb.style.display = 'flex';
                  }
                  else {
                    mb.style.display = 'none';
                  }
                }
              }
            });
          });

          if (currentItem.classList.contains(`opened`) && !currentItem.classList.contains(`accordion__item`)) {
            header.addEventListener(`touchmove`, headerTouchmoveHandler);
          } else {
            header.removeEventListener(`touchmove`, headerTouchmoveHandler);
          }
        }
      }
    };

    const headerTouchmoveHandler = (evt) => {
      const targetContent = evt.target.closest(`.main-nav__content`);
      if (!targetContent) {
        evt.preventDefault();
        return false;
      }
    };

    const mainNavItemMouseenterHandler = (evt) => {
      if (window.innerWidth >= 1280) {
        const target = evt.target.closest(`.main-nav__item`);
        if (target.querySelector('.main-nav__content') && target.classList.contains(`accordion__item`)) {
          target.classList.add(`hovered`);
          document.querySelector('.main-nav__overlay').classList.add('active');
        }
      }
    };

    const mainNavItemMouseleaveHandler = (evt) => {
      if (window.innerWidth >= 1280) {
        const target = evt.target.closest(`.main-nav__item`);
        if (target.querySelector('.main-nav__content')) {
          target.classList.remove(`hovered`);
          document.querySelector('.main-nav__overlay').classList.remove('active');
        }
      }
    };
    if (menuToggleBtn) {
      menuToggleBtn.addEventListener(`click`, menuToggleBtnClickHandler);
    }
    mainNavItems.forEach((item) => hoverintent(item, mainNavItemMouseenterHandler, mainNavItemMouseleaveHandler).options({ timeout: 50, interval: 50 }));
    mainNavLinks.forEach((link) => link.addEventListener(`click`, mainNavLinkClickHandler));

  }

  const tabletLink = header.querySelectorAll(".js-tablet-link");
  if ((tabletLink) && (window.innerWidth <= 1280) && (window.innerWidth > 1024)) {
    tabletLink.forEach((item) => {
      item.addEventListener(`click`, function (evt) {
        const currentItem = evt.currentTarget.closest(`.main-nav__item`);
        const overlay = document.querySelector(".main-nav__overlay");

        if (accordions) {
          accordions.forEach((item) => {
            if (item != currentItem) {
              item.classList.remove(`opened`);
            }
          });
        }
        overlay.classList.toggle("active");
        currentItem.classList.toggle("opened");

        if (currentItem.classList.contains("hovered")) {
          currentItem.classList.remove("hovered");
        }
      });
    })
  }
}
