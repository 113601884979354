window.addEventListener(`DOMContentLoaded`, () => {
  const cookiemsg = document.querySelector('.cookiemsg')
  if (cookiemsg) {
    if(!localStorage.getItem('_isShowCookieMsg')) {
      cookiemsg.style.display = 'block';
      document.querySelector('.cookiemsg__bottom_close').addEventListener(`click`, (evt) => {
        cookiemsg.style.display = 'none';
        localStorage.setItem('_isShowCookieMsg', 1);
      });
    }
    else {
      cookiemsg.remove();
    }
  }
});
