/**
 * Поле формы
 * Структура
 * <div class="form-group input trs form__input" data-invalid="" required>
     <input class="t-normal input__input" data-mask="phone" type="tel" name="tel" value="">
     <label class="clr-text t-normal input__label" for="tel">Телефон</label>
     <span class="error-template error_tel">[[+fi.error.tel]]</span>
   </div>
 */


import Mask from '~/js/modules/input-mask';
import autosize from 'autosize';

export default class Input {

  constructor(input) {
    this._element = input
    this._input = input.querySelector(`.input__input`)
    this._label = input.querySelector(`label`)
    // признак обязательности
    this._isRequered = input.hasAttribute('required') ? true : false;

    this._inputFocusHandler = this._inputFocusHandler.bind(this);
    this._inputLabelClick = this._inputLabelClick.bind(this);
    this._inputLabelAutoFill = this._inputLabelAutoFill.bind(this);
    this._inputBlurHandler = this._inputBlurHandler.bind(this);
    this._inputInitHandler = this._inputInitHandler.bind(this);
    this._addInputChecboxHandler = this._addInputChecboxHandler.bind(this);

    this.init()
  }

  /**
   * Автоматичекая высота для textarea
   * @param textarea
   * @private
   */
  _addTextareaAutosize(textarea) {
    autosize(textarea);
  }

  /**
   * Валидация чекбокса "Согласия на обработку персональных данных"
   * @param checkbox
   * @private
   */
  _addInputChecboxHandler() {
    this._input.closest('form').onsubmit = () => {
      if (this._input.checked) {
        this._input.parentElement.classList.remove(`invalid`)
      } else {
        this._input.parentElement.classList.add(`invalid`)
      }
    }
    this._input.onchange = () => {
      if (this._input.checked) {
        this._input.parentElement.classList.remove(`invalid`)
      } else {
        this._input.parentElement.classList.add(`invalid`)
      }
    }
  }
  /**
   * Проверка имени на минимальное количество символов при фокусе
   * @private
   */
  _NameRuleMin(isEngVersion) {
    let $this = this;
    let text = 'Это поле должно быть не меньше 2 символов.';
    if (isEngVersion) {
      text = 'This field must be at least 2 characters long.'
    }

    if (this._input) {
      ['change', 'focus', 'blur'].forEach(event => this._input.addEventListener(event, function () {
        if ($this._input._isRequered) {
          if ($this._input.value.length < 2) {
            $this._element.classList.add(`invalid`);
            $this._element.setAttribute('data-invalid', text);
          }
          else {
            $this._element.classList.remove(`invalid`);
            $this._element.setAttribute('data-invalid', '');
          }
        }
      }, true));
    }

  }
  /**
 * Проверка имени на минимальное количество символов при фокусе, и после этого на наличие хотя бы двух слов
 * @private
 */
  _FullNameRuleMin() {
    let $this = this;
    if (this._input) {
      ['change', 'blur'].forEach(event => this._input.addEventListener(event, function () {
        if ($this._input.value.length < 2) {
          $this._element.classList.add(`invalid`);
          $this._element.setAttribute('data-invalid', 'Это поле должно быть не меньше 2 символов');
        }
        else {
          let i_surname = this.value.split(' ')[0];
          let i_name = false;

          if (this.value.split(' ')[1] !== undefined) {
            i_name = this.value.split(' ')[1];
          }
          if (i_surname && i_name) {
            $this._element.classList.remove(`invalid`);
            $this._element.setAttribute('data-invalid', '');
          } else {
            $this._element.classList.add(`invalid`);
            $this._element.setAttribute('data-invalid', 'Проверьте правильность введённых данных.');
          }
        }
      }, true));
    }
  }
  /**
   * Проверка пароля на минимальное количество символов при фокусе
   * @private
   */
  _passwordSizeMin() {
    let $this = this;
    if (this._input) {
      ['change', 'focus', 'blur'].forEach(event => this._input.addEventListener(event, function() {
        if($this._input.value.length < 6) {
          $this._element.classList.add(`invalid`);
          $this._element.setAttribute('data-invalid', 'Это поле должно быть не меньше 6 символов');
        }
        else {
          $this._element.classList.remove(`invalid`);
          $this._element.setAttribute('data-invalid', '');
        }
      }, true));
    }
  }

  /**
 * Проверка email при фокусе
 * @private
 */
  _mailMin() {
    let $this = this;
    let text = 'Введите корректный email.';
    if (this._element.classList.contains('form-b--en')) {
      text = 'Incorrect email.';
    }
    if (this._input) {
      ['change', 'blur'].forEach(event => this._input.addEventListener(event, function () {
        let contact = this.value.split('@')[0];
        let mail_box = false;
        let mail_mask = false;

        if (this.value.split('@')[1] !== undefined) {
          let address = this.value.split('@')[1];
          mail_box = address.split('.')[0];
          mail_mask = address.split('.')[1];
        }
        //Должен быть и адрес контакта, и адрес почтовой службы, и адрес домена
        if (contact && mail_box && mail_mask) {
          $this._element.classList.remove(`invalid`);
          $this._element.setAttribute('data-invalid', '');
        } else {
          $this._element.classList.add(`invalid`);
          $this._element.setAttribute('data-invalid', text);
        }
      }, true));
    }
  }
  /**
   * Обработка события click для элемента label
   * @private
   */
  _inputLabelClick() {
    if (!this._input.value) {
      this._element.classList.toggle(`active`);
      if(this._element.classList.contains(`active`)) {
        this._input.focus();
      }
    }
  }
  /**
   * Обработка события автозаполнения в браузере для элемента label
   * @private
   */
  _inputLabelAutoFill() {
    if (this._input.value) {
      this._element.classList.add(`active`);
    }
  }
  /**
   * Обработка события focusin для элемента input
   * @private
   */
  _inputFocusHandler() {
    this._element.classList.add(`active`)
    this._input.focus()
    if (!this._label.classList.contains('feedback_questionPersonalData_no_star')) {
      this._label.style.color = '#ad033b'
    }
  }

  /**
   * Обработка события blur для элемента input
   * @private
   */
  _inputBlurHandler() {
    let text = 'Это поле обязательно для заполнения.';
    if (this._element.classList.contains('form-b--en')) {
      text = 'This field must not be empty.';
    }
    if (this._input.value) {
      this._element.classList.add(`active`);
    }
    else {
      this._element.classList.remove(`active`)
    }
    this._label.removeAttribute('style')
    let attrName = '';
    if(this._input.hasAttribute('data-mask')) {
      attrName = this._input.getAttribute('data-mask');
    }
    if(this._isRequered) {
      // не чекбокс
      if (!this._input.classList.contains('input__input--checkbox')) {
        if (this._input.value) {
          if (attrName !== 'date') {
            this._element.classList.remove(`invalid`);
            this._element.setAttribute('data-invalid', '');
          }
        } else {
          if (this._element.hasAttribute('required')) {
            this._element.classList.add(`invalid`);
            this._element.setAttribute('data-invalid', text);
          }
        }
      }
    }
  }

  /**
   * Действия при создании элемента
   * @private
   */
  _inputInitHandler() {
    if (this._input) {
      if (this._input.value) {
        this._element.classList.add(`active`);
      } else {
        this._element.classList.remove(`active`);
      }
    }
    if(this._isRequered) {
      let span = document.createElement('span');
      span.className = "clr-red";
      span.innerHTML = "*";
      this._label.append(span);
    }
  }

  _addFileHandler(wrap) {
    //Работа с загрузкой файлов
    const formFileTitle = wrap.querySelector('.js-file-title'),
          formInput = wrap.querySelector('.js-file-input');

    formInput.addEventListener('change', function() {
      let defaultFileText = 'Прикрепить файл (Не более 4 файлов)'
      let countFileText = ''
      //Прикреплено 4 и менее файлов
      if(this.files.length > 0 && this.files.length <= 4) {
        countFileText = `Прикреплено файлов: ${this.files.length}`
        formFileTitle.innerText = countFileText
        wrap.classList.remove('invalid')
        wrap.setAttribute('data-invalid', '')
        //Прикреплен большой файл
        for (const file of this.files) {
          console.log(file.size);
          if (file.size > 5242880) {
            formInput.value = ''
            formFileTitle.innerText = defaultFileText
            wrap.classList.add('invalid')
            wrap.setAttribute('data-invalid', 'Файл больше 5МБ!')
          }
        }
        //Прикреплено более 4 файлов
      }else if(this.files.length > 4) {
        if(this.files.length <= 4) {
          countFileText = `Прикреплено файлов: ${this.files.length}`
        } else {
          countFileText = `Прикреплено файлов: 4`
        }

        formFileTitle.innerText = countFileText;
        if(!wrap.querySelector('.js-file-del')) {
          let del = document.createElement('span')
          del.classList.add('file__del')
          del.classList.add('js-file-del')
          del.innerText = '(Очистить)'
          wrap.querySelector(".js-file-upload").append(del)

          //Обработчик очистки файлов
          del.addEventListener('click', function() {
            formInput.value = ''
            formFileTitle.innerText = defaultFileText
            wrap.classList.remove('invalid')
            wrap.setAttribute('data-invalid', '')
            this.remove()
          })
          wrap.classList.add('invalid')
          wrap.setAttribute('data-invalid', 'Можно прикрепить не более 4-х файлов')
        }

        //Нажали Отмена
      }else{
        formFileTitle.innerText = defaultFileText;
        formInput.value = "";
        wrap.classList.remove("invalid");
        wrap.setAttribute("data-invalid", "");
      }
    });
  }

  init() {
    let isEngVersion = false;
    if (this._element.classList.contains('form-b--en')) {
      isEngVersion = true;
    }

    // добавление класса active
    this._inputInitHandler();

    // маска для многострочного текста
    const textarea = this._element.querySelector(`.input__input--textarea`)
    if (textarea) {
      this._addTextareaAutosize(textarea)
    } else {
      // маски
      new Mask(this._element)
    }

    if(this._element.classList.contains('form__file')) {
      this._addFileHandler(this._element);
    }

    // Валидация чекбокса "Согласия на обработку персональных данных"
    if (this._input && this._input.classList.contains('input__input--checkbox')) {
      this._addInputChecboxHandler(this._input);
    }

    //имя
    const inputname = this._element.querySelector(`.input__input[data-mask='name']`);
    if (this._input == inputname) {
      this._NameRuleMin(isEngVersion);
    }
    //телефон
    const inputphone = this._element.querySelector(`.input__input[data-mask='phone']`);
    if (this._input == inputphone) {
      this._NameRuleMin(isEngVersion);
    }
    //фио
    const inputfullname = this._element.querySelector(`.input__input[data-mask='fullname']`);
    if (this._input == inputfullname) {
      this._FullNameRuleMin();
    }
    //пароль
    const pass = this._element.querySelector(`.input__input[data-mask='password']`);
    if (this._input && this._input == pass ) {
      this._passwordSizeMin();
      this._input.addEventListener(`input`, this._inputLabelAutoFill);
    }
    const inputmail = this._element.querySelector(`.input__input[data-mask='email']`);
    if (this._input == inputmail) {
      this._mailMin(isEngVersion);
    }

    if (this._input) {
      // события
      this._input.addEventListener(`focusin`, this._inputFocusHandler)
      //следующие события ломают поле email
      if (this._input != inputmail) {
        this._label.addEventListener(`click`, this._inputLabelClick)
        this._input.addEventListener(`blur`, this._inputBlurHandler)
      }
    }

  }
}
