/**
 * Всплывающие окна для личного кабинета аналитики и форм обратной связи.
 * Открытие-закрытие окон, инициализация форм, работа табов в формах и всплывающих подсказок для кнопок открытия окна.
 */
import Form from '~/js/modules/form';
import MicroModal from "micromodal/dist/micromodal.min";

window.addEventListener('DOMContentLoaded', () => {

  // кнопки или ссылки открытия окна MicroModal
  let microModaltriggerElements = document.querySelectorAll('.js-micromodal-open')
  if(microModaltriggerElements.length) {
    microModaltriggerElements.forEach((microModaltriggerElement) => {
      microModaltriggerElement.addEventListener(`click`, function (event) {
        // кнопка открытия окна
        let triggerElement = event.currentTarget;
        // показать окно
        MicroModal.show(triggerElement.getAttribute('data-micromodal-id'), {
          onShow: function(modal) {
            const openLink = modal.querySelector('.js-open-link')
            const openKidsLink = modal.querySelector('.js-open-kids-link')
            if(triggerElement.getAttribute('data-title')) {
              modal.querySelector('.modal__title').textContent = triggerElement.getAttribute('data-title');
            }
            if(triggerElement.getAttribute('data-desc')) {
              modal.querySelector('.modal__desc').textContent = triggerElement.getAttribute('data-desc');
            }
            // подставляем ссылки на ID фонда в соответствии с страницой
            if (openLink) {
              if(triggerElement.getAttribute('data-open-link')) {
                openLink.setAttribute('href', triggerElement.getAttribute('data-open-link'))
              } else {
                openLink.setAttribute('href', openLink.getAttribute('data-default-href'))
              }
            }
            if (openKidsLink) {
              if(triggerElement.getAttribute('data-open-kids-link')) {
                openKidsLink.setAttribute('href', triggerElement.getAttribute('data-open-kids-link'))
              } else {
                openKidsLink.setAttribute('href', openKidsLink.getAttribute('data-default-href'))
              }
            }
            // открыть нужный таб
            if(triggerElement.getAttribute('data-tab-element')) {
              let tab = triggerElement.getAttribute('data-tab-element');
              modal.querySelector(`[data-tab=${tab}]`).click();
            }
            // сбросить recaptcha и добавить дата аттрибуты к форме
            const forms = modal.querySelectorAll('.form__form');
            if (forms.length) {
              forms.forEach((form) => {

                if(triggerElement.getAttribute('data-lead-type')) {
                  form.setAttribute('data-lead-type', triggerElement.getAttribute('data-lead-type'));
                }
              });
            }
          },
          onClose: function(modal, triggerElement, eventTriggerElement) {
            // отмена всплытия события
            eventTriggerElement.preventDefault();
            eventTriggerElement.stopPropagation();
            if(modal.querySelector(".modal__title")) {
              modal.querySelector(".modal__title").style.visibility = "visible";
            }
            if(modal.querySelector(".modal__desc")) {
              modal.querySelector(".modal__desc").style.display = "block";
            }
            if(modal.querySelector(".success_show")){
              modal.querySelector(".success_show").classList.remove('success_show'); // удаление класса для восстановления отступов поп-апа
            }
            // очистить сообщения на всех формах
            const messagesBlocks = document.querySelectorAll('.form__success-content-wrapper')
            if (messagesBlocks.length) {
              messagesBlocks.forEach((messagesBlock) => {
                if(!messagesBlock.classList.contains('not-modal')) {
                  messagesBlock.classList.remove('active');
                }
              });
            }
            const forms = modal.querySelectorAll('.form__form')
            // очистить форму и сбросить recaptcha
            if (forms.length) {
              forms.forEach((form) => {
                form.style.display = 'flex';
                form.reset();
                const ta = form.querySelector('textarea')
                if(ta) {
                  form.querySelector('textarea').style.height = '72px'
                  form.querySelector('.counter-comment__current').textContent = '0'
                }
              });
            }
          },
          disableScroll: true,
        });
      });

    });
  }

  // инициализация форм
  const forms = document.querySelectorAll('.form__form')
  if (forms.length) {
    forms.forEach((form) => {
      new Form(form)
    });
  }

  if($_GET('openPopupAnalyticsProfile')) {
    document.querySelector('[data-micromodal-id=modal-analytics-profile]').click()
  }

  if($_GET('openPopupAnalyticsProfile')) {
    window.history.replaceState(null, null, window.location.pathname);
  }

  function $_GET(key) {
    var s = window.location.search;
    s = s.match(new RegExp(key + '=([^&=]+)'));
    return s ? s[1] : false;
  }

});

//

const modalOpenBtns = document.querySelectorAll("[data-modal-open]");
const modalCloseBtns = document.querySelectorAll("[data-modal-close]");

const modalOpenHandler = (name) => {
    document.querySelectorAll("[data-modal]").forEach((modal) => {
        if(modal.dataset.modal == name){
            modal.classList.add("is-active")
        }
    })
    document.querySelector("body").classList.add("no-scroll")
}

const modalCloseHandler = (name) => {
    document.querySelectorAll("[data-modal]").forEach((modal) => {
        if(modal.dataset.modal == name){
            modal.classList.remove("is-active")
        }
    })
    document.querySelector("body").classList.remove("no-scroll")
}

if(modalOpenBtns){
    modalOpenBtns.forEach((el) => {
        el.addEventListener("click", (e) => {
            e.preventDefault();
           modalOpenHandler(el.dataset.modalOpen)
        })
    })
}

if(modalCloseBtns){
    modalCloseBtns.forEach((el) => {
        el.addEventListener("click", () => {
           modalCloseHandler(el.dataset.modalClose)
        })
    })
}
