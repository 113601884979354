const ratingForm = document.querySelector(`.js-rating-form`);
if (ratingForm) {
    const form = ratingForm.querySelector('form')
    const successForm = ratingForm.querySelector('.form__success-content-wrapper')
    const switchFormButtons = document.querySelectorAll(`.js-switch-window`);
    const ratingField = ratingForm.querySelector(`.js-rating-field`);
    const ratingButtonsDiv = ratingForm.querySelector(`.js-rating-inputs`);
    const ratingButtons = ratingForm.querySelectorAll(`.js-rating-input`);
    const fileUpload = ratingForm.querySelector(".js-file-upload");
    const formFileTitle = fileUpload.querySelector(".js-file-title");
    const formInput = fileUpload.querySelector(".js-file-input");

    if (switchFormButtons) {
        switchFormButtons.forEach((el) => {
            el.addEventListener('click', () => {
                ratingForm.querySelector(`.js-side-form`).classList.toggle(`hidden`);
                if (form.style.display == 'none') {
                    form.style.display = 'block';
                }
                if (successForm.classList.contains('active')) {
                    //если закрыли форму после успешной отправки, надо сбросить
                    successForm.classList.remove('active');

                    ratingButtons.forEach(el => {
                        if (el.classList.contains('active')) {
                            el.classList.remove('active');
                        }
                    })
                    ratingField.value = "Нет оценки";

                    formFileTitle.innerText = "Прикрепить файл (не более 4 файлов)";
                    formInput.value = "";
                    wrap.classList.remove("invalid");
                    wrap.setAttribute("data-invalid", "");
                }
            })
        })
    }

    if (ratingButtons) {
        ratingButtons.forEach((el, index) => {
            el.addEventListener('click', () => {
                ratingField.value = "Оценка " + (parseInt(index) + 1);

                let prevActiveButton = ratingButtonsDiv.querySelector(`.active`)
                if (prevActiveButton) {
                    prevActiveButton.classList.remove(`active`);
                }
                el.classList.add(`active`);
            })
        })
    }
}
